import React from "react";
import {openInNewTab, handleCtaLinkClick, addClickEventToOmnitureObject, substituteLinks} from "../../utils";
import { ActionButton, Lozenge } from "@able/react";
import TimelineTD from '../../presenters/components/TimelineTD';
import StatusIcon from "./TimelineStatusIcon";
import TimelineMilestonesTD from "../../presenters/components/TimelineMilestonesTD";
import InfoIcon from "../../images/icon-info-blue.svg";
import RedAlertIcon from "../../images/red-alert-icon.svg";
import * as analytics from '../../analytics-utils';
import * as constants from "../../presenters/Constants";
import StarTrackIcon from "../../images/Star Track.svg";
import AustraliaPostIcon from "../../images/icon-auspost.svg";
import {isMobile} from "react-device-detect";
import AddToCalendar from "../../presenters/components/AddToCalendar";
import FileSaver from 'file-saver';
import LinkBehaviour from "./LinkBehaviour";

const timelineColor = '#0064D2';

export default function GroupTimeline({ timelineGroup, page, header, location, accessToken, orderDetails }) {
  const {groupFooter, groupHeader, groupTimeline} = timelineGroup;
  //let timelines = groupTimeline?.sort(getCompareFunc('displaySequence'));
  const isAuthenticated = (page.loggedIn && accessToken) || window.env.REACT_APP_DEV_ENV === 'true';
  let timelines = getTimelines(groupTimeline, location, isAuthenticated, header, orderDetails);

  return (
    <React.Fragment>
      <div className="order-details-timeline" id="order-details-timeline">
        {page && page.loggedIn && header && header.formattedAddress && groupHeader.addressLabel && (
          <div className="timeline-group-header responsive-width">
            <span>{groupHeader.addressLabel}{' '}{header.formattedAddress}</span>
          </div>
        )}
        <div className="timeline-group-header responsive-width">
          {groupHeader && groupHeader.connectionDate && groupHeader.connectionDateLabel && (
            <div>
              <div id="connection-date-label">
                {groupHeader.connectionDateLabel}
              </div>
              <div id="connection-date">
                {groupHeader.connectionDate}
              </div>
            </div>
          )}
        </div>


        <TimelineTD className="order-timeline-timeline-items">
          {timelines.map(timeline => (
            <TimelineMilestonesTD
              accessibleTitle
              {...timeline.props}
              key={timeline.props.displaySequence}
            >
                {timeline.children}
              </TimelineMilestonesTD>
          ))}
        </TimelineTD>

        {groupFooter.guaranteeDetails && groupFooter.guaranteeDetails.message &&
          <div className="responsive-width timeline-container nbn-guarantee-message">
            <p> 
              <span>{ groupFooter.guaranteeDetails.message }</span> 
              <a target="_blank" className="nbn-guarantee-link" href={ groupFooter.guaranteeDetails.linkURL }>
                { groupFooter.guaranteeDetails.linkLabel }
              </a>.
            </p>
          </div>
        }    

        {groupFooter.heading &&
            <div className="responsive-width timeline-group-footer">
              <h3 role="subheading" tabIndex="0">{groupFooter.heading}</h3>
              <span tabIndex="0">{groupFooter.label}</span>
              <div>
                <ActionButton
                    tabIndex="0"
                    element="button"
                    variant="LowEmphasis"
                    onClick={e => {
                      openInNewTab(e, groupFooter.linkURL)
                      addClickEventToOmnitureObject(groupFooter.linkLabel)
                    }}
                    label={groupFooter.linkLabel}/>
              </div>
            </div>
        }
      </div>
    </React.Fragment>
  );
}

function getTimelines(groupTimelines, location, isAuthenticated, header, orderDetails) {
  groupTimelines = groupTimelines || [];
  
  return groupTimelines.map((timeline, index, a) => {
    const timelineProps = {
      props: {
        title: timeline.label,
        icon: getIconByStatus(timeline.status||''),
        lineColor: timeline.status?.toLowerCase() === 'completed' ? timelineColor : '#939393',
        key: timeline.displaySequence,
        displaySequence: timeline.displaySequence
      },
      children: renderTimelineBody(timeline, location, isAuthenticated, header, orderDetails)
    }

    // Don't show line after last timeline
    if(index === a.length - 1) timelineProps.props.lineStyle = {display: 'none'};

    return timelineProps;
  })
}

function renderTimelineBody(timeline, location, isAuthenticated, header, orderDetails) {
  if(!timeline) return null;
  return (
    <React.Fragment>

      {getTopLabelsAndLinkLabels(timeline, location, isAuthenticated, header, orderDetails)}
      {getBottomLabelsAndLinks_1(timeline)}
      {getBottomLabelsAndLinks_2(timeline)}
      {getBottomMessage(timeline, header)}

    </React.Fragment>
  )
}

function getAlertIcon(iconName, placeholder = false) {
  let cls = '';
  let icon = '';
  let alt = '';
  switch (iconName) {
    case 'ALERT':
      cls = 'red-alert-icon--edgar';
      icon = RedAlertIcon;
      alt = 'Alert icon';
      break;
    case 'INFO':
      cls = 'info-icon--edgar';
      icon = InfoIcon;
      alt = 'Info icon';
      break;
    default:
      return '';
  }

  return (
    <div className={cls}>
      {!placeholder && <img src={icon} alt={alt} />}
    </div>
  )  
}

function getBoxImage(boxName) {
  switch (boxName) {
    case 'StarTrack':
      return StarTrackIcon;
    case 'Australia Post':
      return AustraliaPostIcon;
    default:
      return '';
  }
}

function onTrackDeliveryClick(e, trackingURL, orderDetails) {
  analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.TRACK_YOUR_DELIVERY_CLICK_ANALYTICS, orderDetails);
  openInNewTab(e, trackingURL);
}

function getIconByStatus(status) {
  return <StatusIcon status={status}/>;
}

function getLozengeVariant(color, status) {
  switch (color) {
    case 'GREEN_POSITIVE_EMPHASIS':
      return 'PositiveEmphasis';
    case 'GREEN_POSITIVE':
      return 'Positive';
    case 'RED_NEGATIVE_EMPHASIS':
      return 'NegativeEmphasis';
    case 'RED_NEGATIVE':
      return 'Negative';
    case 'GREEN':
      if (status === 'completed')
        return 'PositiveEmphasis'
      return 'Positive';
    case 'RED':
      if (status === 'completed')
        return 'NegativeEmphasis'
      return 'Negative';
    default:
      return 'NeutralEmphasis';
  }
}

function getAddToCalendar(event) {
  let icon = { textOnly: 'none' };
  let calendarForMobile = null;

  if (isMobile) {
    return (
        <div id="Add-To-Calender" className="flex-row add-to-calender-td">
          <a href="javascript:window.location.reload(false)" onClick={(e) => {
            var icsCalendar = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:" + e + "\nDTSTART:" + event.startTime + "Z" + "\nDTEND:" + event.endTime + "Z" + "\nLOCATION:" + event.location + "\nSUMMARY:" + event.title + "\nDESCRIPTION:" + event.description + "\nEND:VEVENT\nEND:VCALENDAR";
            var blob = new Blob([icsCalendar], { type: 'text/plain;charset=utf-8' })
            FileSaver.saveAs(blob, 'Appointment.ics')
          }}>
            <AddToCalendar listItems={calendarForMobile} />
          </a>
        </div>
    );
  }
  return (
      <div id="Add-To-Calender" className="flex-row add-to-calender-td">
        <AddToCalendar event={event} buttonLabel="Add to your calendar" buttonTemplate={icon} />
      </div>
  );
}

function getTopLabelsAndLinkLabels(timeline, location, isAuthenticated, header, orderDetails) {

  return <div>

    {timeline.statusText &&
      <div className="timeline-lozenge">
        <Lozenge
            label={timeline.statusText}
            variant={getLozengeVariant(timeline.statusTextColor, timeline.status.toLowerCase())}
            className="custom-class"
        />
      </div>
    }

    {timeline.top_labelText &&
      <div className="timeline-section">
        {timeline.top_labelPreIcon && getAlertIcon(timeline.top_labelPreIcon)}
        <span className="timeline-message">{renderTopLabelText(timeline, timeline.top_labelText, header)}</span>
      </div>
    }

    {timeline.top_labelText_1 &&
        <div className="timeline-section">
          {timeline.top_labelPreIcon_1 && getAlertIcon(timeline.top_labelPreIcon_1)}
          <span className="timeline-message">{renderTopLabelText(timeline, timeline.top_labelText_1, header)}</span>
        </div>
    }

    {timeline.top_labelText_2 &&
      <div className="timeline-section">
          {timeline.top_labelPreIcon_2 && getAlertIcon(timeline.top_labelPreIcon_2)}
          <span className="timeline-message">{renderTopLabelText(timeline, timeline.top_labelText_2, header)}</span>
      </div>
    }

    {timeline.labelTextList &&
        <div className="timeline-section">
          <ul>
            {timeline.labelTextList.map(label => (
                <li key={label}>{label}</li>
            ))}
          </ul>
        </div>
    }

    {timeline.boxName &&
        <div className="timeline-section">
          <div className="logistic-panel-td">
            <div className="logistic-img-box">
              <img id="box-icon" className="logistic-img-td" src={getBoxImage(timeline.boxImage)} alt={timeline.boxName}/>
            </div>
            <div className="flex-column">
              <h3 id="box-text" className="logistic-label-td">{timeline.boxName}</h3>
              <a
                  id="track-link"
                  className="logistic-link-td"
                  onClick={e => {onTrackDeliveryClick(e, timeline.trackingURL, orderDetails)}}>
                <span id="Tracking-Url" className="tracking-url">{timeline.boxLinkLabel}</span>
              </a>
            </div>
          </div>
        </div>
    }

    {timeline.middle_labelText &&
        <div className="timeline-section timeline-middle-label-text">
          {timeline.middle_labelPreIcon && getAlertIcon(timeline.middle_labelPreIcon)}
          {timeline.middle_labelText &&
              <span className="timeline-message">{timeline.middle_labelText}</span>
          }
        </div>
    }

    {timeline.linkLabelText && timeline.linkLabelURL && !timeline.linkLabelObject &&
        <div className="timeline-section">
          <LinkBehaviour location={location} linkLabelText={timeline.linkLabelText} linkLabelURL={timeline.linkLabelURL} linkBehaviourMobile={timeline.linkBehaviourMobile} linkBehaviourDesktop={timeline.linkBehaviourDesktop} isAuthenticated={isAuthenticated} />
        </div>
    }

    {timeline.linkLabelText_1 && !timeline.linkLabelObject_1 &&
        <div className="timeline-section">
          <LinkBehaviour location={location} linkLabelText={timeline.linkLabelText_1} linkLabelURL={timeline.linkLabelURL_1} linkBehaviourMobile={timeline.linkBehaviourMobile_1} linkBehaviourDesktop={timeline.linkBehaviourDesktop_1} isAuthenticated={isAuthenticated} />
        </div>
    }

    {timeline.linkLabelText_2 && timeline.linkLabelURL_2 && (
      <div className="timeline-section">
        <ActionButton
          element="button"
          variant="LowEmphasis"
          onClick={e => {
              openInNewTab(e, timeline.linkLabelURL_2)
              addClickEventToOmnitureObject(timeline.linkLabelText_2)
          }}
          label={timeline.linkLabelText_2}
          tabIndex="0"
        />
      </div>
    )}

    {timeline.linkLabelObject &&
        <div className="timeline-section">
          {getAddToCalendar(timeline.linkLabelObject)}
        </div>
    }

    {timeline.linkLabelObject_1 &&
        <div className="timeline-section">
          {getAddToCalendar(timeline.linkLabelObject_1)}
        </div>
    }

  </div>
}

function renderTopLabelText(timeline, top_labelText, header) {
  if (top_labelText.includes('<OpenChat>') && top_labelText.includes('</OpenChat>')) {
    let linkText = top_labelText.substring(
      top_labelText.lastIndexOf("<OpenChat>") + 10,
      top_labelText.lastIndexOf("</OpenChat>")
    );
    let textElements = top_labelText.split(linkText)
    const preLinkText = textElements[0].replace("<OpenChat>", "")
    const postLinkText = textElements[1].replace("</OpenChat>", "")
    return <span >
      {preLinkText}
      <a onClick={(e) => handleCtaLinkClick(e, 'open_chat', timeline.chatObject?.mobileUrl, timeline.chatObject?.tags, header)}>{linkText}</a>
      {postLinkText}
    </span>
  }

  return <span>{substituteLinks(top_labelText)}</span>
}

function getBottomLabelsAndLinks_1(timeline) {
  return <div>
    {timeline.bottom_labelText &&
        <div className="timeline-section timeline-bottom-label-text">
          {timeline.bottom_labelPreIcon && getAlertIcon(timeline.bottom_labelPreIcon)}
          {timeline.bottom_labelText &&
              <span className="timeline-message">{timeline.bottom_labelText}</span>
          }
          {timeline.bottom_labelPostIcon && getAlertIcon(timeline.bottom_labelPostIcon)}
        </div>
    }

    {timeline.bottom_labelText_1 &&
        <div className="timeline-section timeline-bottom-label-text">
          {timeline.bottom_labelPreIcon_1 && getAlertIcon(timeline.bottom_labelPreIcon_1)}
          {timeline.bottom_labelText_1 &&
              <span className="timeline-message">{timeline.bottom_labelText_1}</span>
          }
          {timeline.bottom_labelPostIcon_1 && getAlertIcon(timeline.bottom_labelPostIcon_1)}
        </div>
    }
  </div>
}

  function getBottomLabelsAndLinks_2(timeline) {
    return <div>
      {timeline.bottom_labelText_2 &&
          <div className="timeline-section timeline-bottom-label-text">
            {timeline.bottom_labelPreIcon_2 && getAlertIcon(timeline.bottom_labelPreIcon_2)}
            {timeline.bottom_labelText_2 &&
                <span className="timeline-message">{timeline.bottom_labelText_2}</span>
            }
            {timeline.bottom_labelPostIcon_2 && getAlertIcon(timeline.bottom_labelPostIcon_2)}
          </div>
      }

      {timeline.bottom_labelText_3 &&
          <div className="timeline-section timeline-bottom-label-text">
            {timeline.bottom_labelPreIcon_3 && getAlertIcon(timeline.bottom_labelPreIcon_3)}
            {timeline.bottom_labelText_3 &&
                <span className="timeline-message">{timeline.bottom_labelText_3}</span>
            }
            {timeline.bottom_labelPostIcon_3 && getAlertIcon(timeline.bottom_labelPostIcon_3)}
          </div>
      }
    </div>
}

function getBottomMessage(timeline, header) {

  let messageLinkText = '';
  if (timeline.message && timeline.message.includes('<OpenChat>') && timeline.message.includes('</OpenChat>')) {
    let linkText = timeline.message.substring(
        timeline.message.lastIndexOf("<OpenChat>") + 10,
        timeline.message.lastIndexOf("</OpenChat>")
    );
    let textElements = timeline.message.split(linkText)
    const preLinkText = textElements[0].replace("<OpenChat>", "")
    const postLinkText = textElements[1].replace("</OpenChat>", "")
    messageLinkText = <span >
      {preLinkText}
      <a onClick={(e) => handleCtaLinkClick(e, 'open_chat', timeline.chatObject?.mobileUrl, timeline.chatObject?.tags, header)}>{linkText}</a>
      {postLinkText}
    </span>;
  } else if (timeline.message && timeline.message.includes('<NewLine>')) {
    let lineBreaker =  timeline.message.split('<NewLine>')
    messageLinkText = <span >
      {lineBreaker.map((item) =>  <div className="timeline-message-newline"> {item} </div>)}
    </span>;
  } else if(timeline.message) {
    messageLinkText = timeline.message;
  }

  return <div>
    {timeline.heading &&
        <h2 className="milestone-heading">
          {timeline.heading}
        </h2>

    }

    {timeline.message  &&
        <div className="timeline-section">
          {timeline.message_preIcon && getAlertIcon(timeline.message_preIcon)}
          {timeline.message &&
              <span className="timeline-message">{messageLinkText}</span>
          }
          {timeline.message_postIcon && getAlertIcon(timeline.message_postIcon)}
        </div>
    }

    {timeline.messageLinkLabelText &&
        <div className="timeline-section">
          <ActionButton
              element="button"
              variant="LowEmphasis"
              onClick={e => {
                openInNewTab(e, timeline.messageLinkLabelURL)
                addClickEventToOmnitureObject(timeline.messageLinkLabelText)
              }}
              label={timeline.messageLinkLabelText}
              tabIndex="0"
          />
        </div>
    }
  </div>
}